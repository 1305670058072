.App {
  text-align: center;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  background-color: #a5a5a5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.dcc {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.LoginPageContainer {
  min-height: 100vh;
  display: flex;
  overflow-x: "auto";
  /* justify-content: center; */
  align-items: center;
  background: linear-gradient(#99e8ae,#cdfad9, #cdfad9, #99e8ae);
  flex-direction: column;
}

.inputPicker {
  text-align: left;
  border: none;
  color: rgb(0, 0, 0);
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  /* border-bottom: 2px solid #E3B924; */
}
.reportSearchUser{
  background-color: white;
  width: 100%;
  border: none;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}

.ReportButtonText {
  width: 100%;
  font-weight: bold;
  font-size: 25px;
  align-items: center;
  padding: 5px
} 

.ReportButtonContainer {
  display: flex;
  width: 60%;
  margin-top: 25px;
  background-color: white;
  border: 3px solid #09396e;
  border-radius: 5px;
}

.ReportsButton{
  border: none;
  background-color: transparent;
  font-weight: bold;
  font-size: 20px;
}

.reportSearchDate{
  width: '100%';
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid black;
}

.UserPwContainer {
  display: flex;
  margin-top: 8%;
  width: 90%;
  height: 70%;
  flex-direction: column;
  align-items: center;
  /* background-color: #e3edfc; */
}

.ChangePwPageContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
}

.LoginUsernameContainer {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  border: 2px solid black;
  border-radius: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: white;
  padding-top: 7px;
  padding-bottom: 7px;
  /* margin-left: 5%; */
}

.ChangePasswordContainer {
  width: 95%;
  flex-direction: row;
  justify-content: center;
  border: 2px solid black;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 5px;
  background-color: white;
  padding-top: 7px;
  padding-bottom: 7px;
}

.MainContainer {
  width: 65%;
  color: "black";
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  border-width: 0ch;
}
.LoginUsernameInputTextBox {
  width: 50%;
  font-size: 22;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  border-width: 0ch;
}
.PasswordInputTextBox {
  margin-left: 50px;
  width: 50%;
  font-size: 22;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  border-width: 0ch;
}
.showHidepwText {
  font-size: calc(8px + 2vmin);
  /* font-weight: bold; */
  margin-right: 5px;
}

.LoginButton {
  width: 45%;
  border-radius: 25px;
  padding: 8px;
  background-color: #13ab63;
  border: 2px solid #0c854c;
  margin-top: 10px;
}
 /* Menu Design */
 .MainContainerMenu {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  /* background-color: #e3edfc; */
  background: linear-gradient(#689EA8,#217B9A, #225884, #192f6a);
}
.MenuBorderDesign {
  /* background: rgba(255, 255, 255, 0.4); */
  display: flex;
  width: 98%;
  margin-bottom: 8%;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 5px;
  flex-direction: row;
  /* padding-top: 10px; */
  /* padding-bottom: 20px; */
}
.MenuUser {
  text-align: left;
  width: 80%;
  color: white;
  margin-top: 10px;
  font-size: calc(28px + 2vmin);
  font-family: serif;
  font-weight: bold;
}
.rateText {
  text-align: center;
  width: 80%;
  color: white;
  margin-top: 10px;
  font-size: calc(12px + 2vmin);
  font-family: serif;
  font-weight: bold;
}
.MenuButtonDesign {
  /* box-shadow: 5px 5px 5px #060029; */
  display: 'flex';
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  width: 30%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  /* width: calc(85% / 3); */
  /* background-color: white; */
}
.MenuBottomButtonDesign {
  box-shadow: 4px 4px 4px #dbdada;
  display: 'flex';
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  border-radius: 15px;
  margin: 5px;
  background: rgba(255, 255, 255, 0.6);
  width: calc(85% / 3);
  /* background-color: white; */
}
.MenuBotoomButtonBorder {
  width: 98%;
  flex-direction: row;
  display: flex;
  justify-content: center;
}
.MenuButtonFontSize {
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  margin-bottom: auto;
}

.LoginButtonText {
  font-weight: bold;
  font-size: 26px;
  color: white;
  text-align: center;
}

.LanguageButtonContainer {
  display: flex;
  width: 90%;
  height: 25%;
  justify-content: flex-end;
  margin-top: 30px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3edfc;
  flex-direction: column; */
}

.LanguageButton {
  display: flex;
  width: 40%;
  height: 25%;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #13ab63;
  border: 2px solid #0c854c;
  margin-top: 5px;
}

.LanguageButtonText {
  font-weight: bold;
  font-size: 22px;
  color: white;
  text-align: center;
}

.showhideButton {
  background-color: white;
  border: 0px solid #0c854c;
}

.loadingSize {
  width: 15vmin;
  height: 15vmin;
}

.App-link {
  color: #61dafb;
}

.mainHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 660px;
  background-color: #09487b;
  height: 50%;
  font-size: calc(8px + 2vmin);
  /* top: 0;
  position: fixed; */
  /* padding-bottom: 20px; */
}

.ResultHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 660px;
  height: 50%;
  font-size: calc(8px + 2vmin);
}

.leftHeaderText {
  width: 25%;
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-top: 7px;
}
.mainRightHeader {
  height: 100%;
  flex-direction: row;
  justify-content: center;
  width: 60%;
  border: 0px;
  background-color: #09487b;
}
.mainLeftHeader {
  /* height: 100%; */
  flex-direction: row;
  justify-content: center;
  width: 25%;
  border: 0px;
  background-color: #09487b;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
}
.mainRightFunctionButton {
  width: 40%;
  border: 0px;
  /* background-color: #83c8fc; */
  background: linear-gradient(#ff8c00,#ff0000);
  border-radius: 5px;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  color:#ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.MidHeaderText {
  width: 100%;
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-top: 7px;
}
.MainContainer {
  display: flex;
  width: 90%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #e3edfc;
}
.MenuButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  border: 3px solid #09396e;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  /* height: 100%; */
}
.MenuIcon {
  width: calc(50px + 2vmin);
  height:calc(50px + 2vmin);
  align-self: auto;
  margin-bottom: auto;
}
.MenuButtonText {
  display: flex;
  flex-direction: column;
  width: 60%;
  font-weight: bold;
  font-size: calc(18px + 2vmin);
  align-self: center;
  text-align: center;
  /* margin-top: auto; */
  color: #000000;
}
.informationRedWord {
  /* width: 65px;
  margin-left: 10%;
  margin-right: 15px; */
  font-size: 40px;
  font-weight: bold;
  color: #cc1100;
  text-align: center;
}
.InformationHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15%;
  /* background-color: blue; */
}
.InformationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
  overflow: auto;
}
.InformationDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
  overflow: auto;
}
.InformationFooterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 10%;
  border: 0px;
  align-self: center;
  border-radius: 5px;
  background-color: #f2c9c9;
}

.InformationText {
  font-size: 20px;
  font-weight: bold;
  flex-wrap: wrap;
}

.InformationFooterText {
  /* padding-top: 14px; */
  font-size: 20px;
  font-weight: bold;
  flex-wrap: wrap;
}
.infoModalBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
}
.InfoImage {
  align-self: center;
  width: 185;
  height: 185px;
}
.bettingIDContainer {
  flex: 0 1 auto;
  display: flex;
  width: 100%;
  height: 45px;
  background-color: #13ab63;
  border-bottom: 1px solid #09396e;
}
.BTPageContainer {
  display: flex;
  position: fixed;
  /* max-width: 660px; */
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #e3edfc;
  max-width: 660px;
}
.btIDButton {
  display: flex;
  width: 70%;
  border: 0.5px solid black;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  padding-top: calc(1px + 1vmin);
  padding-bottom: calc(1px + 1vmin);
  background-color: white;
  color:#000000;
}

.btIDButtonText {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: bold;
  font-size: 22px;
  align-self: center;
  text-align: left;
  padding-top: 14px;
}
.br23 {
  margin-left: auto;
  margin-right: auto;
  max-width: 660px;
  height: 100%;
}

.btSendButton {
  display: flex;
  width: 30%;
  background-color: #129959;
  border: 0.5px solid black;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  color: white;
  justify-content: center;
  padding-top: calc(1px + 1vmin);
  padding-bottom: calc(1px + 1vmin);
}

.btSendButtonText {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: bold;
  /* font-size: 22px; */
  font-size: calc(15px + 2vmin);
  align-self: center;
  text-align: center;
  padding-top: 14px;
  color: white;
}
.KeyBoardContainer {
  /* flex: 0 1 600px;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 660px; */
  /* padding: 10px; */
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.FormulaContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #cbd2fd;
  width: 100%;
  align-items: center;
}
.FormulaAmountText {
  color: black;
  width: 25%;
  text-align: center;
  flex-wrap: wrap;
  font-size: calc(8px + 2vmin);
  padding-top: calc(1px + 1vmin);
  padding-bottom: calc(1px + 1vmin);
  /* margin-left: auto;
  margin-right: auto; */
}
.FormulaDisplayText {
  font-weight: bold;
  color: black;
  width: 50%;
  text-align: center;
  font-size: calc(9px + 2vmin);
  /* margin-left: auto;
  margin-right: auto; */
}
.FullScreenheight {
  width: 100%;
  overflow-x: auto;
}
.SettingButtons {
  width: 100%;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  background-color: #f0f0f0;
  padding: 10px;
  font-weight: bold;
  text-align: left;
}
.SettingLanguageButtons {
  width: 100%;
  max-width: 660px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background-color: #f0f0f0;
  padding: 8px;
  font-weight: bold;
}
.SettingTitle {
  width: 100%;
  justify-content: center;
  background-color: #0347ad;
  padding: 10px;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}
.FormulaLineText {
  text-align: center;
  width: 25%;
  font-size: 18px;
}
.PressAbleContainer {
  display: flex;
  width: 100%;
  background-color: white;
  height: 22%;
  justify-content: center;
  /* margin-top: auto; */
}
.SimpleBTPressAbleContainer {
  display: flex;
  width: 100%;
  background-color: white;
  height: 18%;
  justify-content: center;
  /* margin-top: auto; */
}
.firstRowButton {
  width: 20%;
  border: 0.5px solid grey;
  font-size: calc(15px + 1vmin);
  font-weight: bold;
  color:#000000;
  /* padding-top: 8px;
  padding-bottom: 8px; */
}

.SimpleBTRowButton {
  width: 25%;
  border: 0.5px solid grey;
  font-size: calc(15px + 1vmin);
  font-weight: bold;
  color:#000000;
  /* padding-top: 8px;
  padding-bottom: 8px; */
}

.SimpleBTfirstRowButton {
  width: 25%;
  border: 0.5px solid grey;
  font-size: calc(15px + 1vmin);
  font-weight: bold;
  color:#000000;
  /* padding-top: 8px;
  padding-bottom: 8px; */
}

.SimpleBTSecondNumPadIconLabel {
  width: 100%;
  height: 33.33333%;
  border: 0.5px solid grey;
  font-size: calc(14px + 2vmin);
  font-weight: bold;
  color:#000000;
}

.SimpleBTNumPadButton {
  width: 33.333333%;
  height: 33.33333%;
}

.NumberContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  /* background-color: rgb(247, 140, 140); */
  height: 60%;
  /* margin-top: auto; */
}
.NumPadButton {
  width: 33.333333%;
  height: 33.33333%;
}
.SecondNumpadContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 60%;
  height: 100%;
}
.SecondNumPadIconLabel {
  width: 50%;
  height: 33.33333%;
  border: 0.5px solid grey;
  font-size: calc(14px + 2vmin);
  font-weight: bold;
  color:#000000;
}
.SecondNumpadMiddleContainer {
  display: flex;
  flex-direction: row;
  height: 60%;
}
.SimpleBTNumpadMiddleContainer {
  display: flex;
  flex-direction: row;
  height: 80%;
}
.SecondNumpadContainer2 {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 40%;
  height: 100%;
}
.SimpleBTNumpadContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 25%;
  height: 100%;
}
.SimpleBTNumpadContainer2 {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 75%;
  height: 100%;
}
.NumPadIconLabel {
  width: 100%;
  height: 100%;
  border: 0.5px solid grey;
  font-size: calc(14px + 2vmin);
  font-weight: bold;
  color:#000000;
}

.BackSpaceIcon {
  width: calc(30px + 2vmin);
  height: calc(30px + 2vmin);
}
.enterIcon {
  width: calc(24px + 2vmin);
  height: calc(24px + 2vmin);
}
.btWordContainer {
  /* flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto; */
  display: flex;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding: 60px 0;
  flex-direction: column;
}
.WordButtonText {
  display: flex;
  width: 96%;
  flex-wrap: wrap;
  margin-left: 5px;
  font-weight: bold;
  font-size: 19px;
}
.SpecialWordButtonText {
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  margin-left: 5px;
  font-weight: bold;
  font-size: calc(9px + 2vmin);
  color: purple;
}
.spBTSpecialWordButtonText {
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  margin-left: 5px;
  font-weight: bold;
  font-size: calc(4px + 2vmin);
  color: purple;
}
.spWordSize {
  display: flex;
  font-weight: bold;
  font-size: calc(8px + 2vmin);

}
.BettingMultilineInputContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
  background-color: #f5eccb;
  height: auto;
  border: 0.5px solid black;
  padding-top: calc(1px + 1vmin);
  padding-bottom: calc(1px + 1vmin);
  color:#000000;
}
.BettingMultilineInputSelectedContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
  background-color: #ffffff;
  height: auto;
  border: 0.5px solid black;
  padding-top: calc(1px + 1vmin);;
  padding-bottom: calc(1px + 1vmin);;
  color:#000000;
}
.BettingMultilineInputSelectedSGDContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
  background-color: #a2e5fa;
  height: auto;
  border: 0.5px solid black;
  padding-top: calc(1px + 1vmin);;
  padding-bottom: calc(1px + 1vmin);;
  color:#000000;
}
.redBettingMultilineInputContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
  background-color: #f5eccb;
  /* min-height: 45px; */
  height: auto;
  border: 0.5px solid black;
  padding-top: 7px;
  padding-bottom: 7px;
  /* position: relative; */
  color:#000000;
}
.redBettingMultilineInputSelectedContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
  background-color: #ffe6e6;
  /* min-height: 45px; */
  height: auto;
  border: 0.5px solid black;
  padding-top: 7px;
  padding-bottom: 7px;
  /* position: relative; */
  color:#000000;
}

.BT-container {
  background-color: #f84545;
}
.BT-head {
  /* flex: 0 1 auto; */
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  background-color: rgba(162, 192, 232, 0.5);
  height: 12%;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.box .BT-body {
  flex: 1 1 auto;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  background-color: #ffffff;
  /* height: 45%; */
  /* height: 50vh; */
}

.bdclass {
  margin: 0;
  padding: 0;
}

.DateModalBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: 500px;
  border: 1px solid black;
}

.DateModalHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  /* background-color: #ff0000; */
}

.DateModalHeaderText {
  display: flex;
  width: 50%;
  font-size: 20px;
  font-weight: bold;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
  /* justify-content: center; */
}

.DateModalCloseButton {
  display: flex;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border: 0px;
  background-color: rgb(255, 255, 255);
  color:#000000;
}

.dateListButton {
  display: flex;
  width: 100%;
  border: 0.5px solid black;
  align-items: center;
  font-weight: bold;
  font-size: 22px;
  background-color: white;
  border: 0.5px solid;
  padding-top: 6px;
  padding-bottom: 6px;
  color:#000000;
}

.dateListFooter {
  display: flex;
  margin-top: auto;
  flex-wrap: wrap;
}
.dateFooterButton {
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
  background-color: white;
  border: 0.5px solid;
  color:#000000;
}

.CheckmarkIcon {
  width: calc(24px + 2vmin);
  /* height: calc(24px + 2vmin); */
  margin-left: auto;
  margin-right: 10px;
}
.confirmMationHeaderText {
  display: flex;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  justify-content: center;
}

.Receipt-body {
  /* flex: 1 1 auto; */
  display: flex;
  overflow-y: auto;
  background-color: rgb(255, 255, 255);
  flex-direction: column;
  height: 85%;
  text-align: left;
  align-items: center;
  /* height: 50vh; */
}
.Receipt-foot {
  display: flex;
  flex-direction: row;
  /* background-color: rgba(200, 255, 0, 0.5); */
  width: 100%;
  max-width: 660px;
  height: 8%;
  margin-top: auto;
  position: fixed;
  bottom: 0;

}
.ReceiptSubmit {
  width: 50%;
  font-size: calc(14px + 2vmin);
  font-weight: bold;
}
.ReceiptSubmitText {
  font-size: 20px;
  font-weight: bold;
}
.SimpleBTDateContainer {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size:  calc(14px + 2vmin);
  font-weight: bold;
  text-align: left;
}
.SimpleBTDateInput {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1.5px solid;
}
.SimpleBTDateInputButton {
  width: 14%;
  border: 2px solid black;
  background-color: #daeef5;
  border-radius: 40px;
  /* font-size: calc(10px + 2vmin); */
  font-weight: bold;
  color:#000000;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
}
.SimpleBTDateText {
  width: 80%;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
}
.SimpleBTButtonContainer {
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
}
.SimpleBTAddNumButton {
  width: 40%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 25px;
  background-color: #1e702b;
  color: #ffffff;
  border: 1px solid #096e18;
  border-radius: 4px;
  font-size: calc(10px + 3vmin);
  font-weight: bold;
}
.SimpleBTClearAllButton {
  width: 40%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 25px;
  background-color: #f2c9c9;
  color: #522e2b;
  border: 1px solid #ff837a;
  border-radius: 6px;
  font-size: calc(10px + 3vmin);
  font-weight: bold;
}
.SimpleBTWordListHead {
  display: flex;
  width: 100%;
  background-color: #7db5d4;
  border: 0.5px solid black;
  /* margin-top: 10px; */
}
.SimpleBTWordListHeadText {
  display: flex;
  width: 100%;
  /* background-color: #09396e; */
  /* color: #ffffff; */
  font-size: calc(9px + 2vmin);
  font-weight: bold;
  padding-top: 2px;
  padding-bottom: 2px;
}
.SpecialMultilineContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
  background-color: #ffffff;
  height: auto;
  border: 0.5px solid black;
  /* padding-top: 5px;
  padding-bottom: 5px; */
}

.PlatformListContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: bold;
  flex-wrap: wrap;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 2px solid black;
}
.PlatformButtonContainer {
  display: flex;
  flex-direction: row;
  min-width: 25%;
  border: 0px solid black;
  font-weight: bold;
  font-size: 22px;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.SimpleBTModalPlatformButtonContainer {
  display: flex;
  flex-direction: row;
  /* min-width: 10%;
  max-width: 12%; */
  border: 0px solid black;
  font-weight: bold;
  font-size: 22px;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}

.PlatformListIcon {
  width: calc(25px + 2vmin);
  height: calc(25px + 2vmin);
  max-width: calc(25px + 2vmin);
  max-height: calc(25px + 2vmin);
}
.SelectedPlatformListIcon {
  width: calc(25px + 2vmin);
  height: calc(25px + 2vmin);
  max-width: calc(25px + 2vmin);
  max-height: calc(25px + 2vmin);
  border: 3px solid #33d660;
  border-radius: 50%;
}
.PlatformListCheckmarkIcon {
  width: calc(25px + 2vmin);
  height:  calc(25px + 2vmin);
  max-width: calc(25px + 2vmin);
  max-height: calc(25px + 2vmin);
  border: 2px solid black;
  margin-left: auto;
  margin-right: auto;
}
.PlatformListCheckmarkIconText {
  width: calc(25px + 2vmin);
  height:  calc(25px + 2vmin);
  max-width: calc(25px + 2vmin);
  max-height: calc(25px + 2vmin);
  border: 2px solid black;
  margin-left: auto;
  margin-right: auto;
  color: #b8b8b8;
  font-weight: bold;
  font-size: calc(12px + 2vmin);
}
.SimpleBTNumberInputContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 70%;
  border: 2px solid grey;
  border-radius: 4px;
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  background-color: #ffffff;
}
.SimpleBTNumberInput {
  width: 40%;
  border: 2px solid black;
  border-radius: 4px;
  /* padding-top: calc(1px + 2vmin);
  padding-bottom: calc(1px + 2vmin); */
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  text-align: center;
}
.SimpleBTModeInput {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 2px solid black;
  border-radius: 4px;
  /* padding-top: calc(1px + 2vmin);
  padding-bottom: calc(1px + 2vmin); */
  font-weight: bold;
  font-size: calc(12px + 2vmin);
}
.SimpleBTAmountKey2Text {
  display: flex;
  flex-direction: row;
  padding-top: calc(1px + 2vmin);
  /* padding-bottom: calc(1px + 2vmin); */
  font-weight: bold;
  font-size: calc(12px + 2vmin);
}
.SimpleBTAmountInputContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: calc(1px + 2vmin);
  padding-bottom: calc(1px + 2vmin);
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  background-color: #003791;
  color: white;
  border-radius: 4px;
  border-top-right-radius: 0px;
}
.SimpleBTAmountInput {
  display: flex;
  flex-direction: column;
  width: "25%";
}
.SimpleBTAmountInputBox {
  border-radius: 4px;
  text-align: center;
  font-size: calc(10px + 2vmin);
  padding-top: calc(0.5px + 1vmin);
  padding-bottom: calc(0.5px + 1vmin);
  font-weight: bold;
}
.SimpleBTAddCloseContainer {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  /* margin-bottom: 5px; */
}
.AddNumModalBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  min-height: calc(600px + 2vmin);
  max-height: calc(800px + 2vmin);
  border: 1px solid black;
}
.AddNumModalAddButton {
  width: 30%;
  background-color: #1e702b;
  color: white;
  border: 1px solid #096e18;
  padding-top: calc(0.3px + 1vmin);
  padding-bottom: calc(0.3px + 1vmin);
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  border-radius: 4px;
  margin-right: auto;
  margin-left: auto;
}
.AddNumModalCloseButton {
  width: 30%;
  background-color: #f2c9c9;
  color: "#522e2b";
  border: 1px solid #ff837a;
  padding-top: calc(0.5px + 2vmin);
  padding-bottom: calc(0.5px + 2vmin);
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  border-radius: 4px;
  /* margin-left: 10px; */
  color: #000000;
}
.NumberRangeInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.NumberRangeInput {
  width: 40%;
  border: 1px solid #000000;
  padding-top: calc(0.5px + 2vmin);
  padding-bottom: calc(0.5px + 2vmin);
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  border-radius: 4px;
  text-align: center;
}
.SimpleBTRemoveWordBTN {
  margin: auto;
  font-size: calc(4px + 2vmin);
  border: 2px solid red;
  border-radius: 50%;
  padding-top: calc(0.1px + 1vmin);
  padding-bottom: calc(0.1px + 1vmin);
  padding-left: calc(1px + 1vmin);
  padding-right: calc(1px + 1vmin);
  background-color: #ffffff;
  /* height: 100%; */
}
.SimpleBTModalRemoveWordBTN {
  margin: auto;
  font-size: calc(8px + 2vmin);
  border: 2px solid red;
  border-radius: 50%;
  padding-top: calc(0.2px + 1vmin);
  padding-bottom: calc(0.2px + 1vmin);
  padding-left: calc(2px + 1vmin);
  padding-right: calc(2px + 1vmin);
  height: 50%;
}
.SimpleBTWordContainer {
  display: flex;
  flex-direction: row;
}
.ReceiptMangeUserText {
  justify-content: center;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  margin-left: auto;
}
.ReceiptManageButton {
  display: flex;
  width: 100%;
  border: 2px solid #09396e;
  align-self: center;
  font-size: calc(13px + 2vmin);
  font-weight: bold;
  padding-top: calc(0.8px + 2vmin);
  padding-bottom: calc(0.8px + 2vmin);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  margin-top: calc(6px + 2vmin);
  justify-content: center;
  color: #000000;
}
.ReceiptDateButton {
  display: flex;
  width: 100%;
  border: 2px solid #09396e;
  align-self: center;
  font-size: calc(13px + 2vmin);
  font-weight: bold;
  padding-top: calc(0.8px + 1vmin);
  padding-bottom: calc(0.8px + 1vmin);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  margin-top: calc(6px + 2vmin);
  justify-content: center;
}
.ReceiptSelectDateIcon {
  align-items: center;
  margin-left: 5px;
  width: calc(20px + 2vmin);
}
.DatePickContainer {
  display: flex;
  width: 100%;
  text-align: center;
  border: 0px;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  margin-left: auto;
}
.ReceiptTitleFont {
  font-size: calc(12px + 2vmin);
  font-weight: bold;
}
.DateByCheckBoxContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.dateByCheckBoxButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  border: 1px;
  align-items: center;
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}
.dateByCheckMarkIcon {
  width: calc(25px + 2vmin);
  height: calc(25px + 2vmin);
  border: 2px solid black;
  margin-left: 10px;
}
.ReceiptSearchTextbox {
  width: 100%;
  font-size: calc(11px + 2vmin);
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #09396e;
}
.ReceiptSearchButton {
  width: 100%;
  font-size: calc(13px + 2vmin);
  font-weight: bold;
  text-align: center;
  border: 2px solid #09396e;
  border-radius: 5px;
  background-color: #1065aa;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ReceiptContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid #09396e;
  text-align: left;
}
.ReceiptFeatureContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.ReceiptFeatureButton {
  font-weight: bold;
  border: 2px solid #09396e;
  border-radius: 3px;
  font-size: calc(9px + 2vmin);
  padding: calc(0.5px + 0.5vmin);
  padding-left: calc(1px + 1vmin);
  padding-right: calc(1px + 1vmin);
  margin-right: 5px;
  margin-top: 5px;
  background-color: #1065aa;
  color: rgb(255, 255, 255);
}
.ReceiptDisplayText {
  font-size: calc(13px + 2vmin);
  font-weight: bold;
}

.box .ReceiptFooterFeatureContainer {
  max-width: 660px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  flex-wrap: wrap;
  /* position: fixed;
  bottom: 0; */
  margin-bottom: 5px;
}
.ReceiptFooterFeatureButton {
  font-size: calc(13px + 2vmin);
  font-weight: bold;
  color: #ffffff;
  border: 2px solid #0f56a3;
  border-radius: 4px;
  background-color: #1065aa;
}

.PageHeaderMainContainer {
  display: flex;
  flex-direction: row;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: calc(2px + 1vmin);
  padding-bottom: calc(2px + 1vmin);
  background-color: #09487b;
  color: white;
}
.PageHeaderLeftContainer {
  width: 25%;
}
.PageHeaderLeftBackButton {
  width: 100%;
  background-color: #09487b;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  color: white;
  text-align: center;
}
.PageHeaderMiddleContainer {
  width: 50%;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  padding-top: calc(0.5px + 1vmin);
  padding-bottom: calc(0.5px + 1vmin);
}
.PageHeaderRightContainer {
  width: 25%;
}

.HeaderLogoutButton {
  width: 80%;
  /* border: 2px solid #54b9ff; */
  /* background-color: #000440; */
  color: white;
  border-radius: 4px;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  flex-wrap: wrap;
  white-space: pre-wrap;
  margin-left: auto;
  margin-right: auto;
}

.PageUserSelectnSendContainer {
  display: flex;
  flex-direction: row;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: #09487b; */
  color: white;
}
.displayIDforSearch {
  display: flex;
  width: 98%;
  border-bottom: 0.5px solid black;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  padding-top: calc(1px + 1vmin);
  padding-bottom: calc(1px + 1vmin);
  color: black;
}
.box .PageKeyboardContainer {
  flex: 0 1 40%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  min-height: 40%;
}
.box .SIMPLEBTPageKeyboardContainer {
  flex: 0 1 35%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  min-height: 35%;
}
.box .resultFooter {
  flex: 0 1 7%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  min-height: 7%;
  background-color: white;
  /* position: fixed; */
  /* bottom: 0; */
}

.box .BT-HEAD {
  flex: 0 1 auto;
}
.box .Report-body {
  flex: 1 1 auto;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  /* height: 45%; */
  /* height: 50vh; */
}
.reportDisplayText {
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: pre-wrap;
  text-align: left;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  margin-left: 5px;
}

.reportFooterButtonContainer {
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
}
.box .Report-HEAD {
  flex: 0 1 auto;
}
.reportFooterButton {
  width: 50%;
  padding-top: calc(2px + 2vmin);
  padding-bottom: calc(2px + 2vmin);
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  height: 100%;
  border: 0.5px solid black;
}
.resultDateText {
  display: flex;
  width: 100%;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  border-bottom: 2px solid;
  padding-bottom: 2px;
}
.SimpleBTAmountRight {
  display: flex;
  width: 100%;
  font-size: calc(9px + 2vmin);
  justify-content: right;
  margin-right: 5px;
  font-weight: bold;
  padding-top: 2px;
  padding-bottom: 2px;
}

.AlertModalBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: hidden;
  min-height: 100px;
  max-height: 275px;
  border: 1px solid black;
}

.AlertModalHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
}

.AlertModalHeaderText {
  display: flex;
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.AlertModalContentBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  max-height: 225px;
  font-size: 18px;
  font-weight: bold;
  overflow-y: auto;
}
.AlertModalContentFooter {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  align-items: center;
  /* border: 1px solid black; */
}
.AlertModalCloseButton {
  display: flex;
  width: 40%;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  border: 1px;
  background-color: #f2c9c9;
  color: "#522e2b";
  border: 1px solid #ff837a;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}
.Pad2NumPadButton {
  width: 20%;
  height: 33.33333%;
}
.NumPad2ButtonIcon {
  width: 100%;
  height: 100%;
  border: 0.5px solid grey;
  color:#000000;
}

.Pad2SelectedFormulaButton {
  width: 20%;
  border: 0.5px solid grey;
  font-size: calc(15px + 1vmin);
  font-weight: bold;
  color:#ff6600;
  /* padding-top: 8px;
  padding-bottom: 8px; */
}

.SimpleBTDateIcon {
  width: calc(18px + 2vmin);
  height:  calc(20px + 2vmin);
  max-width: calc(18px + 2vmin);
  max-height: calc(20px + 2vmin);
  /* border: 2px solid black; */
  /* border-radius: 48 / 2; */
  margin-left: auto;
  margin-right: auto;
}
.SimpleBTContainer {
 display: flex;
 flex-direction: row;
 width: 100%;
 background-color: #ffffff;
}
.SimpleBTSpecialSelectButton {
  display: flex;
  flex-direction: row;
  width: 30%;
  border: 2px solid grey;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: calc(8px + 2vmin);
  font-weight: bold;
  color:#000000;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
}
.SimpleBTUserSelectButton {
  display: flex;
  flex-direction: row;
  width: 45%;
  border: 2px solid grey;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: calc(8px + 2vmin);
  font-weight: bold;
  color:#000000;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
}
.SimpleBTSpecialSelectText {
  width: 75%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.SimpleBTPlatformListContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: bold;
  flex-wrap: wrap;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: white;
}
.SimpleBTContentContainer {
  display: flex;
  flex-direction: row;
  top: 0;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
  background-color: #ffffff;
  color: white;
}
.SimpleBTPlatformListIcon {
  width: calc(26px + 2vmin);
  height: calc(26px + 2vmin);
  max-width: calc(26px + 2vmin);
  max-height: calc(26px + 2vmin);
}
.SimpleAmountPfDisplay {
  display: flex;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  border: 2px solid black;
  color: black;
  font-size: calc(7px + 2vmin);
  font-weight: bold;
  justify-content: center;
  background-color: #ffffff;
}

.NameContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  /* border: 2px solid black; */
  background-color: #0976b5;
  padding-top: 3px;
  padding-bottom: 3px;
  flex-wrap: wrap;
  white-space: pre-wrap;
}

.NameInputTextBox {
  width: 62%;
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  border: 1px solid #000000;
  border-radius: 3px;
}

.BalanceText {
  width: 35%;
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  border-width: 0ch;
  color: white;
  align-self: center;
}

.ChinaPressButton {
  width: 45%;
  border-radius: 10px;
  padding: 8px;
  background-color: #F6E902;
  border: 2px solid #000000;
  margin-top: 15px;
}

.ChinaPressButtonText {
  font-weight: bold;
  font-size: 26px;
  color: black;
  text-align: center;
}

.longtermButtonText {
  font-weight: bold;
  font-size: 24px;
  color: black;
  text-align: center;
}

.captchaContainer {
  /* display: flex; */
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  /* margin-top: 15px;
  margin-bottom: 15px; */
  /* background-color: white; */
  /* margin-left: 5%; */
}

.captchaInputTextBox {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  border: 2px solid black;
  border-radius: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.captchaRefreshButton {
  display: flex;
  width: 15%;
  background-color: #13ab63;
  border: 1px solid #0c854c;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 50px;
  justify-content: center;
  /* position: absolute; */
  margin-right: 3px;
}
.captchaRefreshButtonText {
  font-size: calc(10px + 2vmin);
  /* font-weight: bold; */
  color: white;
}

.captchaimgContainer {
  display: flex;
  background-color: #ffbe63;
  width: 45%;
  height: 60px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  /* justify-content: center; */
  /* margin-top: 15px;
  margin-bottom: 15px; */
  /* background-color: white; */
  /* margin-left: 5%; */
}

.captchaImgRefreshContainer {
  width: 100%;
  justify-content: flex-end;
  background-Color: #ffbe63;
  border: 2px solid #086317;
}

.pasteEditModalFooter {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  flex-wrap: wrap;
}
.pasteEditModalFooterButton {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
  background-color: white;
  border: 0.5px solid;
  color:#000000;
}
.pasteEditModalFooterInput {
  display: flex;
  width: 100%;
  border: 0.5px solid black;
  align-items: center;
  font-weight: bold;
  font-size: 22px;
  background-color: white;
  border: 0.5px solid;
  padding-top: 6px;
  padding-bottom: 6px;
  color:#000000;
  background-color: #fffab5;
}
.ModalPlatformTextContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0.5px solid black;
  text-align: center;
}
.ModalPlatformText {
  display: flex;
  width: 25%;
  align-items: center;
  align-self: center;
  justify-content: center;
  font-weight: bold;
  font-size: 22px;
  background-color: white;
  color:#000000;
}
.displayReceiptDateAndDateBy{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.mainRightIcon {
  width: calc(13px + 2vmin);
  height: calc(18px + 2vmin);
  margin-left: auto;
  /* margin-right: 10px; */
}

.BettingMultilineInputSelectedTHBContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
  background-color: #edc2ff;
  height: auto;
  border: 0.5px solid black;
  padding-top: calc(1px + 1vmin);;
  padding-bottom: calc(1px + 1vmin);;
  color:#000000;
}



html,
body {
  /* height: 100%; */
  height: 100vh;
  /* overflow: hidden; */
  margin: 0;
}

.Bbody {
  /* height: 100%; */
  height: 100vh;
  /* overflow: hidden; */
  margin: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
